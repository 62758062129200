<template>
  <ModernModalLayout
    :size="size"
    :title="message('title')"
    :persistent="$wait('onSubmit')"
    :close-disabled="$wait('onSubmit')"
    :watch-for="data"
  >
    <div>
      <div v-if="description" class="mb-2">{{ description }}</div>
      <component
        :is="component"
        v-model="data.innerValue"
        :multiple="multiple"
        v-bind="componentProps"
        :error-messages="getValidationErrors('data.innerValue')"
        @blur="validateField('data.innerValue')"
      />
      <div v-if="message('hint')" class="text-sm-body-2 pt-2">
        {{ message('hint') }}
      </div>
    </div>

    <v-alert v-if="error" type="error" text border="left" class="mt-4">
      {{ error }}
    </v-alert>

    <template #actions:append>
      <v-btn
        class="px-10"
        depressed
        color="primary"
        :disabled="notChanged"
        :loading="$wait('onSubmit')"
        @click="handleSubmit"
      >
        Сохранить
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators';
import validationMixin from '@/utils/validation';
import messagable from '@/utils/mixins/messagable';
import waitable from '@/utils/mixins/waitable';
import { isXhrError, xhrErrorMessage } from '@/utils/helpers';

import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import _isEqual from 'lodash/isEqual';

const MESSAGES = {
  title: 'Редактирование чего-то',
  warning: 'Чего-то не хватает',
  hint: '',
};

export default {
  components: { ModernModalLayout },
  mixins: [waitable, messagable(MESSAGES), validationMixin],

  validations() {
    return {
      data: {
        innerValue: {
          required: requiredIf(() => this.componentProps?.required),
        },
      },
    };
  },

  props: {
    component: { type: Object, required: true },
    value: { type: [Array, Number, String], default: null },
    description: { type: String, default: null },
    onSubmit: { type: Function, required: true },
    multiple: { type: Boolean, default: false },
    size: { type: String, default: 'medium' },
    componentProps: { type: Object, default: null },
  },

  data() {
    return {
      // data data data data (wrapping single value into data object)
      // is needed here in order for 'modernmodallayout' watch function to work
      // anything in data object changed -> modal state becomes 'dirty' ->
      // modal closing prevention
      data: {
        innerValue:
          this.multiple && typeof this.value[0] === 'object'
            ? this.value.map(item => item.id || item.key)
            : this.value,
      },

      error: null,
    };
  },

  computed: {
    notChanged() {
      return _isEqual(this.data.innerValue, this.value);
    },
  },

  methods: {
    async handleSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;

        await this.$loading(this.onSubmit(this.data.innerValue), 'onSubmit');
        this.$emit('close');
      } catch (error) {
        this.error = isXhrError(error)
          ? xhrErrorMessage(error)
          : error?.message || error;
      }
    },
  },
};
</script>
